<template>
  <v-container>
    <v-card>
      <v-card-title>KAWASAKI CHILDREN'S PRIVACY POLICY</v-card-title>
      <v-card-text class="pa-10">
        <h1></h1>
        <p>Effective Date: April 1, 2025</p>

        <p>
          <b>Kawasaki Motors Corp., U.S.A.</b> ("<b>KMC</b>," "<b>we</b>," "<b>us</b>," or "<b>our</b>") operates
          <a href="https://www.kawasakisalesrewards.com" target="_blank" rel="noreferrer"
            >www.kawasakisalesrewards.com</a
          >
          that offers mixed audience services, in that we may offer certain services to children as well as adults. We
          do not knowingly collect personal information as defined by the Children's Online Privacy Protection Act
          ("<b>COPPA</b>") from children under the age of thirteen (13) ("<b>child</b>" or "<b>children</b>") ("<b
            >Children's Personal Information</b
          >"), except as may be permitted by COPPA and explained in this Children's Privacy Policy. If you are a parent
          or guardian and believe that we have collected such personal information other than as permitted by COPPA,
          please contact us as noted below in Section 3.
        </p>
        <p>
          This Children's Privacy Policy explains our information collection, disclosure, and parental consent practices
          with respect to Children's Personal Information provided by children in relation to the Kawasaki Racer Rewards
          Program portions of our online services, and any other Mixed-use Services (defined below) we may offer. This
          Children's Privacy Policy operates in accordance with COPPA. For more information about COPPA and general tips
          about protecting children's online privacy, please visit the Federal Trade Commission's
          <a
            href="https://www.ftc.gov/business-guidance/resources/complying-coppa-frequently-asked-questions"
            target="_blank"
            rel="noreferrer"
            >COPPA FAQs</a
          >.
        </p>
        <ol>
          <li>
            <h3>Mixed Use Sections of Our Services</h3>
            <p>
              We provide services to children, but not predominantly so, (a "<b>Mixed-use Service</b>") on the Kawasaki
              Racer Rewards Program portions of our online services. We may offer other Mixed-use Services from time to
              time, which will post this Children's Privacy Policy, and may post a supplemental notice if necessary to
              disclose additional of different Children's Personal Information practices. On our Mixed-use Services we
              may, consistent with COPPA, age screen users and treat users based on their self-reported age. We will
              limit the collection of Children's Personal Information of confirmed children via our Mixed-use Services
              consistent with COPPA, which means that we will not collect Children's Personal Information from those
              children online for purposes that require verification using COPPA's verified parental consent methods
              without obtaining such verification, and where lesser or no verification is required, we will comply with
              the other applicable provisions of COPPA.
            </p>
            <p>
              KMC has engaged Privacy Vaults Online, Inc. (d/b/a PRIVO) to obtain full verifiable parental consent for
              the Kawasaki Racer Rewards Program account registration. All information collected for verification is
              stored securely and not used for any other purpose. For more information on PRIVO please see PRIVO's
              privacy policy
              <a href="https://www.privo.com/platform-privacy-policy" target="_blank" rel="noreferrer">here</a>.
            </p>
            <p>
              To set up a parental account, PRIVO may collect the parent's (or guardian's) first name, last name,
              mailing address, phone number, date of birth, gender, email address, and secret question (as a reminder
              for a forgotten password). Additionally, the parent or guardian will be asked to verify his or her
              identity.
            </p>
          </li>
          <li>
            <h3>Collection and Use of Children's Personal Information</h3>
            <p>
              Once KMC has obtained verifiable parental consent, KMC will ask the child for Children's Personal
              Information that is reasonably necessary for the child to register and participate in the Kawasaki Racer
              Program or other KMC online activities. We do not condition a child's participation in the Kawasaki Racer
              Rewards Program, on the child's disclosure of more Children's Personal Information than is reasonably
              necessary to participate in the Kawasaki Racer Rewards Program or Sales Rewards enrollments.
            </p>
            <p>
              Types of Children's Personal Information the child may provide us online may include, where reasonably
              necessary to engage in an applicable program activity (such as to receive messages and shipments): name,
              shipping address, phone number, email address, gender, shirt size, username and password, and ownership
              information of products sold by KMC. Parents may also provide us other information, such as the child's
              Social Security Number for the purposes of completing the child's application for a Kawasaki Racer Rewards
              MasterCard™ and tax reporting of rewards, but information provided directly by the parent is not subject
              to COPPA, except as submitted for COPPA-required parental verification. See our Online Privacy Policy,
              which is available
              <a href="https://www.kawasaki.com/en-us/about/legal-policies" target="_blank" rel="noreferrer">here</a>,
              for our online data practices beyond what is governed by COPPA and this Children's Privacy Policy, such as
              parents' or non-children's Kawasaki Racer Rewards activities.
            </p>
            <p>
              We may use the Children's Personal Information collected from identified children through the Kawasaki
              Racer Rewards Program registration (1) to inform Program members (which may be deemed to include the
              parent of a child member) of products and service, and special offers; and (2) for members to participate
              in special member-only events, such as races. This may include informing children and parents about future
              Kawasaki Racer Rewards Program promotions, sweepstakes, contests, and other marketing activities.
            </p>
          </li>
          <li>
            <h3>Parental Choices and Controls</h3>
            <p>
              We allow parents (or guardians) to manage their child's account, including opt-in preferences, by clicking
              "Parents Manage Accounts" on the Kawasaki Racer Rewards Program website. To protect the privacy and
              security of the Kawasaki Racer Rewards Program online, we may take reasonable steps to verify the identity
              of the parent or guardian before granting the parent or guardian access to the personal information that
              we or they collect and maintain about the parent or guardian and his or her child.
            </p>
            <p>
              At any time, a child's parent or guardian may request to review their child's personal information that we
              maintain, limit further collection or use of their child's personal information, or delete their child's
              personal information from our records. Parents and guardians may exercise these rights through our
              <a
                href="https://privacyportal.onetrust.com/webform/045ffde2-647c-41d8-8fd2-848407f04871/81ae21c2-6248-494a-87fa-2c847b419011"
                target="_blank"
                rel="noreferrer"
                >COPPA Requests Webform</a
              >. Requests are subject to our verification process and applicable legal exceptions.
            </p>
            <p>
              California residents are also entitled to additional access, deletion, and other data rights as more fully
              set forth in our California Privacy Notice, which is available
              <a href="https://www.kawasaki.com/en-us/about/legal-policies" target="_blank" rel="noreferrer">here</a>.
            </p>
            <p>
              For privacy issues or concerns regarding the Kawasaki Racer Rewards Program, you may contact us by phone
              at <a href="tel:9497160351">(949) 716-0351</a>, by email at
              <a href="mailto:TeamGreen.Racer@kmc-usa.com">TeamGreen.Racer@kmc-usa.com</a>, or by regular mail at:
            </p>
            <p>
              Kawasaki Racer Rewards Program <br />
              26972 Burbank <br />
              Foothill Ranch, CA 92610
            </p>
            <p>
              To protect children's privacy and security, we will take reasonable steps to help verify a parent's
              identity before granting access to any personal information consistent with COPPA and other applicable
              laws.
            </p>
          </li>
          <li>
            <h3>When Information Collected From Children Is Available To Others</h3>
            <p>
              Notwithstanding anything to the contrary, we may share or disclose Children's Personal Information in a
              limited number of instances as permitted by COPPA, including the following:
            </p>
            <ol>
              <li>
                We may share Children's Personal Information with our service providers if necessary for them to perform
                certain "internal operations" (as defined by COPPA) functions for us.
              </li>
              <li>
                We may disclose Children's Personal Information if permitted or required by law, for example, in
                response to a court order or a subpoena. To the extent permitted by applicable law, we also may disclose
                Children's Personal Information (i) in response to a law enforcement or public agency's (including
                schools or children services) request; (ii) if we believe disclosure may prevent the instigation of a
                crime, facilitate an investigation related to public safety or protect the safety of a child using our
                sites or applications; (iii) to protect the security or integrity of our sites, applications, and other
                technology or services, as well as the technology of our service providers; or (iv) enable us to take
                precautions against liability.
              </li>
              <li>
                As otherwise permitted or required by COPPA or other applicable law, or to the parent or as expressly
                authorized by the parent.
              </li>
            </ol>
          </li>
        </ol>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ChildrenPrivacyPolicy"
};
</script>

<style scoped>
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
